import React, { useEffect, useMemo, useState } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS.js";
import { Point } from "ol/geom";
import { Feature } from "ol/index";
import {
  Fill,
  Style,
  Circle as CircleStyle,
  Icon,
  Stroke,
} from "ol/style";
import { Overlay } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import MultiPoint from "ol/geom/MultiPoint";
import "../../css/Map.css";
import Signin from "../../container/Signin";
import Signup from "../../container/Signup";
import OSM from "ol/source/OSM";
import ForgotPassword from "../../container/ForgotPassword";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import $, { map } from "jquery";
import { fetchUserProfile } from "../../action";
import { connect } from "react-redux";
import { signin } from "../../action";
import MapModal from "./MapModal";
import "./Map.css";
import NoPlanModaldal from "../common/NoPlanModal";
import { useNavigate } from "react-router-dom";
import { fetch_user_plan_detail } from "../../action";
import { useDispatch, useSelector } from "react-redux";
import CreditUseConfirmation from "../common/CreditUseConfirmation";
import { updatePlotDetailsCount } from "./Apis";
import Loader from "../Loader/Loader";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Map1 = (props) => {
  const [coord, setCoord] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [chnageLayer, setChnageLayer] = useState(true);
  const [selextedLayer, setSelextedLayer] = useState(true);
  const [openForgotPassModal, setOpenForgotPassModal] = useState(false);
  const [address, setAddress] = useState("");
  const [loginContainer, setLoginContainer] = useState(false);
  const [Coordinates, setCoordinates] = useState(null);
  const [modalData, setModalData] = useState("");
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [creditUseConfModal, setCreditUseConfModal] = useState(false);
  const [detailsId, setSetailsId] = useState("");
  // const [searchHide, setSearchHide] = useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.Token) {
      setLoginContainer(true);
      props.signin(localStorage.Token);
    }
  }, [openModal]);

  function getCoordinates() {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  const currentLocation = async () => {
    const position = await getCoordinates();
    const coords = [position.coords.longitude, position.coords.latitude];
    setCoord(coords);
  };

  useEffect(() => {
    currentLocation();
    props.fetchUserProfile(localStorage.UserId);
    props.signin(localStorage.Token);
  }, []);

  const point = new Point(coord);

  const vectorLayer = new VectorLayer({
    source: new VectorSource({
      features: [new Feature(point)],
    }),
    style: new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: require("../../assets/image/asset.png"),
      }),
    }),
  });

  const mapLayer = () => {
    const layer = selextedLayer
      ? new TileLayer({
        source: new XYZ({
          url: "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",
        }),
      })
      : new TileLayer({
        source: new OSM(),
      });
    return layer;
  };

  const handleLayerMode = () => {
    setSelextedLayer(!selextedLayer);
    setChnageLayer(!chnageLayer);
  };

  useMemo(() => {
    if (!coord) {
      return;
    }
    const container = document.getElementById("popup");
    const content = document.getElementById("popup-content");
    const closer = document.getElementById("popup-closer");

    if ($("#map-container").data("map") == null) {
      const overlay = new Overlay({
        element: container,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });

      closer.onclick = function () {
        overlay.setPosition(undefined);
        closer.blur();
        return false;
      };

      // const place = [72.6759234, 23.0394734];

      let view = new View({
        center: [72.62, 23],
        projection: "EPSG:4326",
        zoom: 8,
      });

      setTimeout(() => {
        view.animate({
          center: coord,
          zoom: 17,
        });
      }, 2000);

      const map = new Map({
        target: "map-container",
        layers: [mapLayer(), vectorLayer],
        overlays: [overlay],
        view: view,
        controls: [],
      });

      //Set reference for jQuery
      $("#map-container").data("map", map);
      $("#map-container").data("vectorLayer", vectorLayer);

      var layer;
      map.addEventListener("singleclick", function () {
        map.removeLayer(layer);
      });

      var vectorLayer2;
      map.addEventListener("singleclick", function () {
        map.removeLayer(vectorLayer2);
      });

      var wmsSource = new TileWMS({
        url: "https://pinyard.in:9090/geoserver/pinyard/wms",
        tileSize: 512,
        params: { LAYERS: "pinyard:home_plotmap", TILED: true },
        serverType: "geoserver",
        transition: 0.8,
      });

      map.on("singleclick", async function (evt) {
        // var element = popup.getElement();
        const coordinate = evt.coordinate;
        var viewResolution = map.getView().getResolution();
        var url = wmsSource.getFeatureInfoUrl(
          evt.coordinate,
          viewResolution,
          "EPSG:4326",
          {
            INFO_FORMAT: "application/json",
          }
        );

        if (url) {
          await fetch(url)
            .then((response) => response.json())
            .then((data) => {
              if (data.features.length !== 0) {
                // setSearchHide(false);
                // setIsLoading(false);
              } else {
                // setSearchHide(true);
                // setIsLoading(false);
              }
              if (data) {
                if (data.features.length !== 0) {
                  setModalData(data.features[0].properties);
                }
              }
              if (data.features.length === 0) {
                overlay.setPosition(undefined);
              } else {
                const styles_red = [
                  new Style({
                    fill: new Fill({
                      color: "rgba(255, 0, 0, 0.4)",
                    }),
                    stroke: new Stroke({
                      color: "#ffcc33",
                      width: 2,
                    }),
                  }),
                  new Style({
                    geometry: function (feature) {
                      // return the coordinates of the first ring of the polygon
                      const coordinates = feature
                        .getGeometry()
                        .getCoordinates()[0];
                        console.log("coordinates", coordinates);
                      return new MultiPoint(coordinates);
                    },
                  }),
                ];

                const styles_green = [
                  new Style({
                    fill: new Fill({
                      color: "rgba(0, 255, 0, 0.4)",
                    }),
                    stroke: new Stroke({
                      color: "#ffcc33",
                      width: 2,
                    }),
                  }),
                  new Style({
                    geometry: function (feature) {
                      // return the coordinates of the first ring of the polygon
                      const coordinates = feature
                        .getGeometry()
                        .getCoordinates()[0];
                      console.log("coordinates", coordinates);
                      return new MultiPoint(coordinates);
                    },
                  }),
                ];

                const geojsonObject = {
                  type: "FeatureCollection",
                  crs: {
                    type: "name",
                    properties: {
                      name: "EPSG:4326",
                    },
                  },
                  features: [
                    {
                      type: "Feature",
                      geometry: {
                        type: "Polygon",
                        coordinates: [data.features[0].geometry.coordinates[0]],
                      },
                    },
                  ],
                };

                const source = new VectorSource({
                  features: new GeoJSON().readFeatures(geojsonObject),
                });

                if (data.features[0].properties.is_available) {
                  layer = new VectorLayer({
                    source: source,
                    style: styles_green,
                  });
                } else {
                  layer = new VectorLayer({
                    source: source,
                    style: styles_red,
                  });
                }
                {
                  localStorage.Token && map.addLayer(layer);
                }

                const iconFeature = new Feature({
                  geometry: new Point(
                    data.features[0].properties.point.coordinates
                  ),
                });

                let iconStyle;

                if (data.features[0].properties.is_available) {
                  iconStyle = new Style({
                    image: new Icon({
                      anchor: [0.5, 46],
                      anchorXUnits: "fraction",
                      anchorYUnits: "pixels",
                      src: require("../../assets/image/2216335_location_map_map pin_marker_pin_icon.png"),
                    }),
                  });
                } else {
                  iconStyle = new Style({
                    image: new Icon({
                      anchor: [0.5, 46],
                      anchorXUnits: "fraction",
                      anchorYUnits: "pixels",
                      src: require("../../assets/image/asset.png"),
                    }),
                  });
                }

                // set Style in point
                iconFeature.setStyle(iconStyle);

                const vectorSource = new VectorSource({
                  features: [iconFeature],
                });

                vectorLayer2 = new VectorLayer({
                  source: vectorSource,
                });

                {
                  localStorage.Token && map.addLayer(vectorLayer2);
                }

                {
                  localStorage.Token && overlay.setPosition(coordinate);
                }
              }
            });
        }
      });
    } else {
      let map = $("#map-container").data("map");
      let oldVectorLayer = $("#map-container").data("vectorLayer");
      // let view = $("#map-container").data("view")

      //Set reference for new vectorLayer (for future map)
      $("#map-container").data("vectorLayer", vectorLayer);

      let layerSwitcher = $("#map-container").data("layerSwitcher");
      //Remove old and add new vectorLayer
      map.removeLayer(oldVectorLayer);
      map.addLayer(vectorLayer);

      // Remove old and add new View
      map.getView().animate({
        center: coord,
        projection: "EPSG:4326",
        zoom: 17,
      });
    }
  }, [coord, selextedLayer]);

  const handleToken = () => {
    if (localStorage.getItem("Token")) {
      // setIsLoading(true);
      return true;
    } else {
      setOpenModal(true);
    }
  };

  const openSignUp = () => {
    setOpenSignUpModal(true);
    setOpenModal(false);
  };

  const closeSignUpModal = () => {
    setOpenSignUpModal(false);
    // setOpenModal(true);
  };

  const closeForgotPassModal = () => {
    setOpenForgotPassModal(false);
    setOpenModal(true);
  };
  const handleForgotPassModal = () => {
    setOpenForgotPassModal(true);
    setOpenModal(false);
  };
  const openLoginModal = () => {
    setOpenModal(true);
  };
  const closeLoginModal = () => {
    setOpenModal(false);
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const lat = latLng.lat;
    const lng = latLng.lng;
    const newLatLng = [lat, lng];
    setAddress(value);
    setCoordinates([latLng.lng, latLng.lat]);
    setCoord([lng, lat]);
  };

  const closeConfModal = () => {
    setSubscribeModal(false);
  };

  useEffect(() => {
    dispatch(fetch_user_plan_detail(localStorage.UserId));
  }, []);

  const clickData = useSelector((state) => state.User_plan_detail);

  const navigate = useNavigate();

  const handleDetail = async (id) => {
    const link = "/plot_detail/" + id;
    const plots = clickData.visited_plot;
    if (!plots.includes(id)) {
      if (clickData.is_subscribe) {
        setCreditUseConfModal(true);
        setSetailsId(id);
      } else {
        setSubscribeModal(true);
      }
    } else {
      navigate(`${link}`);
    }
  };
  const handleConfirmDetail = async (id) => {
    const link = "/plot_detail/" + id;
    const params = {
      plot_id: id,
    };
    const response = await updatePlotDetailsCount(params);
    if (response) {
      dispatch(fetch_user_plan_detail(localStorage.UserId));
      setCreditUseConfModal(false);
      navigate(`${link}`);
    }
  };

  const closeCreConfModal = () => {
    setCreditUseConfModal(false);
  };
  return (
    <div
      className={`${loginContainer ? "" : "pt-0"} main-container fullscreen`}
    >
      <div>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div
              key={suggestions.description}
              className={`map-searchbar searchbar ${loginContainer ? "" : "without-login"
                }`}
            >
              <input
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div className="serch-loading">Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      padding: "5px 10px",
                    }
                    : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      padding: "5px 10px",
                    };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>

      <div
        id="map-container"
        className={` ${loginContainer ? "" : "without-login"} map-container`}
        onClick={handleToken}
      ></div>
      <div id="popup" className="ol-popup">
        {coord && (
          <MapModal data={modalData} handleDetail={handleDetail} />
        )}
        <a href="#" id="popup-closer" className="ol-popup-closer"></a>
        <div id="popup-content"></div>
      </div>
      {/* <div>
        <div className="modeBtn" onClick={handleLayerMode}>
          <img
            src={` ${chnageLayer ? simpleImg : setelightImg} `}
            className="map-img"
          />
        </div>
      </div> */}

      {openModal && (
        <Signin
          closeModal={closeLoginModal}
          signUpModal={openSignUp}
          handleForgotPassModal={handleForgotPassModal}
        />
      )}
      {openSignUpModal && (
        <Signup closeModal={closeSignUpModal} openLoginModal={openLoginModal} />
      )}
      {openForgotPassModal && (
        <ForgotPassword
          closeModal={closeForgotPassModal}
          openLoginModal={openLoginModal}
        />
      )}
      {subscribeModal && <NoPlanModaldal closeConfModal={closeConfModal} />}
      {creditUseConfModal && (
        <CreditUseConfirmation
          closeConfModal={closeCreConfModal}
          detailsId={detailsId}
          handleConfirmDetail={handleConfirmDetail}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { userData: state.UserProfile };
};

export default connect(mapStateToProps, { fetchUserProfile, signin })(Map1);
