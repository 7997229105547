import React from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PUBLIC_IC_KEY =
  "pk_test_51Q0I6KSJfoXVtY1EDaPJiD3zqV7b89bkzr1EF3qNDz7KJo6dWAR5UeiOJO5F48Qu0ovFKB1IR4oV218qvZtGg168000372XCn8";

  const stripeTestPromise = loadStripe(PUBLIC_IC_KEY);

function StripeContainer() {
  return <Elements stripe={stripeTestPromise}>
     
  </Elements>;
}

export default StripeContainer