import React, { useEffect, useState } from "react";
import { getPlotDetails, updateFevStatus } from "./Apis";
import Favourite from "../common/Favourite";
import ShareButton from "../common/ShareButton";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function MapModal({ data, handleDetail }) {
  const [plotDetail, setPlotDetail] = useState("");
  const [sharePoints, setSharePoints] = useState([]);

  useEffect(() => {
    handlePlotDetails(data.plot_id);
  }, [data]);

  const handlePlotDetails = async (id) => {
    const response = await getPlotDetails(id);
    if (response) {
      setPlotDetail(response.data);
    }
  };

  const handleFav = async (id) => {
    const params = {
      plot_id: id,
    };
    const response = await updateFevStatus(params);
    if (response) {
      handlePlotDetails(id);
    } else {
    }
  };
  const extracrMapPoint = (cords) => {
    const point = cords;
    const pointString = point.substring(17, point.length - 1);
    const pointArray = pointString.split(" ");
    return pointArray;
  };

  useEffect(() => {
    if (plotDetail !== "") {
      setSharePoints(extracrMapPoint(plotDetail.point));
    }
  }, [plotDetail]);

  return (
    <div>
      <table className="modal-table">
        <tbody>
          <tr>
            <td colSpan={2}>
              <div className="modal-title">
                Plot Details
                <div className="favorite-btn-map">
                  <div
                    className="list-favorite-icon"
                    onClick={() => handleFav(plotDetail.id)}
                  >
                    <Favourite favoriteStatus={plotDetail.is_favorite} />
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Title</td>
            <td>{plotDetail ? plotDetail.title : ""}</td>
          </tr>
          <tr>
            <td>Owners Name</td>
            <td>{plotDetail ? plotDetail.owners_name : ""}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{plotDetail ? plotDetail.city : ""}</td>
          </tr>
          <tr>
            <td>Area</td>
            <td>{plotDetail ? plotDetail.area_of_land : ""}</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{plotDetail ? plotDetail.price : ""}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="d-flex align-items-center justify-content-between">
                <p
                  className="mb-0 viewMore-link d-inline"
                  onClick={() => handleDetail(plotDetail.id)}
                >
                  View More
                </p>
                <div className="btn-wrap">
                  <div className="theme-btn">
                    <ShareButton points={sharePoints} />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default MapModal;


// import React, { useEffect, useState } from "react";
// import { getPlotDetails, updateFevStatus } from "./Apis";
// import Favourite from "../common/Favourite";
// import ShareButton from "../common/ShareButton";
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// function MapModal({ data, handleDetail }) {
//   const [plotDetail, setPlotDetail] = useState("");
//   const [sharePoints, setSharePoints] = useState([]);

//   useEffect(() => {
//     handlePlotDetails(data.plot_id);
//   }, [data]);

//   const handlePlotDetails = async (id) => {
//     const response = await getPlotDetails(id);
//     if (response) {
//       setPlotDetail(response.data);
//     }
//   };

//   const handleFav = async (id) => {
//     const params = {
//       plot_id: id,
//     };
//     const response = await updateFevStatus(params);
//     if (response) {
//       handlePlotDetails(id);
//     }
//   };

//   const extractMapPoint = (cords) => {
//     const point = cords;
//     const pointString = point.substring(17, point.length - 1);
//     const pointArray = pointString.split(" ");
//     return pointArray;
//   };

//   useEffect(() => {
//     if (plotDetail !== "") {
//       setSharePoints(extractMapPoint(plotDetail.point));
//     }
//   }, [plotDetail]);

//   return (
//     <div>
//       <table className="modal-table">
//         <tbody>
//           <tr>
//             <td colSpan={2}>
//               <div className="modal-title">
//                 Plot Details
//                 <div className="favorite-btn-map">
//                   <div
//                     className="list-favorite-icon"
//                     onClick={() => handleFav(plotDetail.id)}
//                   >
//                     <Favourite favoriteStatus={plotDetail.is_favorite} />
//                   </div>
//                 </div>
//               </div>
//             </td>
//           </tr>
//           <tr>
//             <td>Title</td>
//             <td>{plotDetail ? plotDetail.title : ""}</td>
//           </tr>
//           <tr>
//             <td>Owners Name</td>
//             <td>{plotDetail ? plotDetail.owners_name : ""}</td>
//           </tr>
//           <tr>
//             <td>Address</td>
//             <td>{plotDetail ? plotDetail.city : ""}</td>
//           </tr>
//           <tr>
//             <td>Area</td>
//             <td>{plotDetail ? plotDetail.area_of_land : ""}</td>
//           </tr>
//           <tr>
//             <td>Price</td>
//             <td>{plotDetail ? plotDetail.price : ""}</td>
//           </tr>
//           <tr>
//             <td colSpan={2}>
//               <div className="d-flex align-items-center justify-content-between">
//                 <p
//                   className="mb-0 viewMore-link d-inline"
//                   onClick={() => handleDetail(plotDetail.id)}
//                 >
//                   View More
//                 </p>
//                 <div className="btn-wrap">
//                   <div className="theme-btn">
//                     <ShareButton points={sharePoints} />
//                   </div>
//                 </div>
//               </div>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default MapModal;
